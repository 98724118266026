import React from 'react';
import {Col} from 'react-materialize'
import WorkbookCard from './WorkbookCard.js';

const WorkbookCards = ({gradeId, workbooks, customerType, isAndroid, isDownloadPrintableFlow}) => (
    <div>
        {workbooks.map(workbook => (
            <Col key={workbook.id} s={6} m={4} l={4}>
                <WorkbookCard workbook={workbook} customerType={customerType} isAndroid={isAndroid} isDownloadPrintableFlow={isDownloadPrintableFlow}/>
            </Col>
        ))}
    </div>
);

export default WorkbookCards;