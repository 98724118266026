import React, {Component} from 'react';
import {Navbar, NavItem} from 'react-materialize'
import {connect} from "react-redux";

class SideNavigation extends Component {

    menuItemClicked = (gradeId) => {
        console.log("grade id is:: ", gradeId);
		this.props.onMenuItemClicked(gradeId);
	};

    ourAppsClicked = () => {
        console.log("Our Apps clicked");
        this.props.ourAppsClicked();
    };

  	render() {
	    return (

            <Navbar left style={{backgroundColor: "rgba(0,0,0,.67)"}} fixed>
				<NavItem href='#!KG' onClick={this.menuItemClicked.bind(this, 'KG')} className={this.getClass("KG")}>Grade KG</NavItem>
                <NavItem href='#!1' onClick={this.menuItemClicked.bind(this, '1')}  className={this.getClass("1")}>Grade 1</NavItem>
                <NavItem href='#!2' onClick={this.menuItemClicked.bind(this, '2')}  className={this.getClass("2")}>Grade 2</NavItem>
                <NavItem href='#!3' onClick={this.menuItemClicked.bind(this, '3')}  className={this.getClass("3")}>Grade 3</NavItem>
                <NavItem href='#!4' onClick={this.menuItemClicked.bind(this, '4')}  className={this.getClass("4")}>Grade 4</NavItem>
                <NavItem href='#!5' onClick={this.menuItemClicked.bind(this, '5')}  className={this.getClass("5")}>Grade 5</NavItem>
            </Navbar>
        );
  	}

    getClass = (identifier) => {
        return this.props.gradeId === identifier ? "active" : "";
    }
}

export default connect(
    (state)=>{
        return { gradeId: state.workbooksReducer.gradeId }
    }
)(SideNavigation);
