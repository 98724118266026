import React, {Component} from 'react';
import {Card, CardTitle} from 'react-materialize'
import {PAID} from '../api-config';

class WorkbookCard extends Component {
    render() {
        const action = (this.props.isDownloadPrintableFlow) ? this.createActionButtonAsPerNewFlow() : this.createActionButtonAsPerOldFlow();
        return (
            <Card
                key={this.props.workbook.id}
                className={"sticky-action"}
                header={<CardTitle reveal image={this.props.workbook.coverUrl} waves='light'> </CardTitle>}
                title={this.props.workbook.name}
                reveal={<div>{this.props.workbook.longDescription}</div>}
                actions={[action]}>
                <p>{this.props.workbook.shortDescription}</p>
            </Card>
        );
    }

    createActionButtonAsPerNewFlow = ()=> {
        let downloadHref = this.props.workbook.url;
        let requestAccessMessage = "Purchase Subscription to get all printables";

        return (this.props.customerType === PAID || this.props.workbook.free) ?
            <a key={this.props.workbook.id}
               href = {downloadHref} onClick={this.downloadPdf.bind(this,downloadHref)} download>Download now</a> :
            <a key={this.props.workbook.id}
               onClick={this.requestAccessHandlerNew.bind(this, requestAccessMessage)}>Request Access</a>;
    };

    createActionButtonAsPerOldFlow = ()=> {
        let downloadHref = `mailto:?subject=Here's your printable ${this.props.workbook.name}!&body=Please Download it from here: ${this.props.workbook.url}%0A%0A Thanks%0A%0AMonster Math Team`;
        let requestAccessHref = `mailto:support@makkajai.com?subject=How to get access to printable: ${this.props.workbook.name}!&body=Hello There,%0A%0AI loved the free printables. How do I get access to paid printables?%0A%0AThanks`;
       return (this.props.customerType === PAID || this.props.workbook.free) ?
            <a key={this.props.workbook.id}
               href={downloadHref} onClick={this.emailMeHandler}>Email me</a> :
            <a key={this.props.workbook.id}
               href={requestAccessHref} onClick={this.requestAccessHandler}>Request Access</a>;
    };

    downloadPdf = () => {
        return true;
    }

    emailMeHandler = () => {
        return true;
    }

    requestAccessHandlerNew = (requestAccessMessage) => {
        alert(requestAccessMessage);
        return true;
    };

    requestAccessHandler = () => {
        return true;
    };
}

export default WorkbookCard;