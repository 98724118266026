import React, {Component} from 'react';
import './../css/App.css';
import {Col, Preloader, Row} from 'react-materialize'
import WorkbookCards from './components/WorkbookCards.js'
import SideNavigation from './components/SideNavigation.js'
import {connect} from 'react-redux'
import {changeGrade, ourAppsClicked, initializeVitals} from './actions/workbookActions'
import queryString from 'query-string';
import OurApps from "./components/OurApps";

class App extends Component {

    getGradeId = () => {
        return this.props.gradeId;
    };

    getToken = () => {
        return this.props.token;
    };

    componentWillMount() {
        this.initializeVitals();
    }

    initializeVitals = () => {
        const params = queryString.parse(this.props.location.search);
        if(params.appsOnly === "true") return;
        this.props.initializeVitals(params.token || "",
            params.customerType || "",
            params.isAndroid || false, params.gradeId || this.getGradeId(), params.isDownloadPrintableFlow || false);
    };

    onMenuItemClicked = (gradeId, event) => {
        console.log("grade id is:: ", gradeId);
        this.props.changeGrade(this.getToken(), gradeId);
    };

    showHeader = () => {
        return <div>
            <SideNavigation onMenuItemClicked={this.onMenuItemClicked} ourAppsClicked={this.ourAppsClicked}>
            </SideNavigation>
        </div>
    };

    showContent = () => {
        const {error} = this.props;
        if(error) {
            return <div className="error-message">Oops! Something went wrong: {error}</div>
        }
        if(this.props.showOurApps) return "";

        return <div>
            {/* This is navigation area   styles={{transform: translateX(0%)}}*/}
            <Row>
                <Col s={12} m={12} l={12}>
                    {/* This is the area where we display workbook cards */}
                    {this.showInnerContent()}
                </Col>
            </Row>

        </div>;
    };

    showInnerContent = () => {
        const {gradeId, showLoader, workbooks, isAndroid, customerType, isDownloadPrintableFlow} = this.props;
        if (showLoader) {
            return <Preloader flashing/>
        } else {
            return <div><h3 style={{textAlign: "center"}}>Grade {gradeId} Printables!</h3><WorkbookCards gradeId={gradeId} workbooks={workbooks} customerType={customerType} isAndroid={isAndroid} isDownloadPrintableFlow={isDownloadPrintableFlow}> </WorkbookCards></div>
        }
    };


    showOurApps = () => {
        return <OurApps isAndroid={this.props.isAndroid}/>
    };

    ourAppsClicked = (event) => {
        console.log("Our Apps clicked");
        this.props.ourAppsClicked();
    };

    showOurAppsIfRequired = () => {
        if(this.props.showOurApps) {
            return <OurApps isAndroid={this.props.isAndroid}/>
        }
        return ""
    };

    render() {
        const params = queryString.parse(this.props.location.search);

        if(params.appsOnly === "true") {
            return (
                <div className="App" style={{height: "100%"}}>
                    {this.showOurApps()}
                </div>
            );
        }
        return (
            <div className="App" style={{height: "100%"}}>
                {this.showHeader()}
                {this.showContent()}
                {this.showOurAppsIfRequired()}
            </div>
        );
    }
}

export default connect(
    (state)=>{
        return state.workbooksReducer;
    },
    (dispatch) => {
        return {
            initializeVitals: (token, customerType, isAndroid, gradeId, isDownloadPrintableFlow) => dispatch(initializeVitals(token, customerType, isAndroid, gradeId, isDownloadPrintableFlow)),
            changeGrade: (token, gradeId) => dispatch(changeGrade(token, gradeId)),
            ourAppsClicked: () => dispatch(ourAppsClicked())
        };
    }
)(App);
