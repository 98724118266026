const initialState = {
    token: "",
    customerType: "free",
    isAndroid: true,
    gradeId: "KG",
    showOurApps: false,
    showLoader: false,
    workbooks: [],
    error: null
};

export default function reducer(state = initialState, actions) {
    switch(actions.type){
        case "FETCH_WORKBOOKS_PENDING":
            console.log(`Fetching workbooks for grade: ${state.gradeId}`);
            return {...state, showLoader: true, showOurApps: false};

        case "FETCH_WORKBOOKS_FULFILLED":
            console.log(`Fetching workbooks FULFILLED for grade: ${state.gradeId} ${actions.payload}`);
            return {...state, showLoader: false, showOurApps: false, workbooks: actions.payload.data };

        case "FETCH_WORKBOOKS_REJECTED":
            console.log(`Fetching workbooks Rejected for grade: ${actions.payload.message}`);
            return {...state, showLoader: false, showOurApps: false, error: actions.payload.message};

        case "CHANGE_GRADE_FULFILLED":
            console.log(`Updating grade to: ${actions.payload}`);
            return {...state, gradeId: actions.payload, showOurApps: false};

        case "SHOW_OUR_APPS_FULFILLED":
            console.log(`Updating show our apps: ${actions.payload}`);
            return {...state, showOurApps: actions.payload, gradeId: "6"};

        case "INITIALIZE_VITALS_FULFILLED":
            console.log(`Initialize vitals: ${actions.payload.token} CustomerType: ${actions.payload.customerType}`);
            return {...state, token: actions.payload.token, customerType: actions.payload.customerType, isAndroid: JSON.parse(actions.payload.isAndroid), gradeId: actions.payload.gradeId,isDownloadPrintableFlow: actions.payload.isDownloadPrintableFlow };

        default:
            return state;
    }
}