let host = "";

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
    host = "http://localhost:8080"
}
console.log(`Host name: ${hostname}`)
if(hostname.indexOf("192.168") >= 0) {
    host = `http://${hostname}:8080`;
}

export const HOST = host;
export const PAID = "paid";