import axios from "axios"
import { HOST } from './../api-config';

export function getWorkbooks(token, gradeId) {
    console.log(`Fetching the books with token: ${token}`);
    return {
            type: "FETCH_WORKBOOKS",
            payload: axios.get(`${HOST}/printables/${gradeId}`,
                {
                    headers: {
                        'Authorization': `Makkajai-authtoken ${token}`,
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*"
                    }
                }
            )
            // payload: new Promise((resolve, reject) => {
            //     setTimeout(()=>{
            //         if(token !== TOKEN) {
            //             resolve({
        // "data": [
        //     {
        //         "name": "Vol 1, Unit 1",
        //         "code": "1_Vol_1_Unit_1",
        //         "password": "",
        //         "shortDescription": "SHORT: First Unit of 1 Vol 1 Book",
        //         "longDescription": "LONG: First Unit of 1 Vol 1 Book",
        //         "sequence": 10,
        //         "url": "https://www.flipsnack.com/monstermath/grade-1-volume-1-playbook.html",
        //         "coverUrl": "https://s3.amazonaws.com/files.flipsnack.net/collections/items/cd2ca026f624552defb0047i86268691/covers/fsdx3ypjurzpyamr/small",
        //         "id": 3,
        //         "free": true
        //     },
        //     {
        //         "name": "Vol 2, Unit 1",
        //         "code": "1_Vol_2_Unit_1",
        //         "password": "",
        //         "shortDescription": "SHORT: First Unit of 1 Vol 2 Book",
        //         "longDescription": "LONG: First Unit of 1 Vol 2 Book",
        //         "sequence": 20,
        //         "url": "https://www.flipsnack.com/monstermath/grade-1-volume-2-playbook.html",
        //         "coverUrl": "https://s3.amazonaws.com/files.flipsnack.net/collections/items/176af4e08934eb3bc829393i86269516/covers/fstnq7s08k9s7oek/small",
        //         "id": 4,
        //         "free": true
        //     }
        // ]
        // });
            //         } else {
            //             reject("Unauthorized Access!");
            //         }
            //     }, 1500);
            // })
    };
}

export function initializeVitals(token, customerType, isAndroid, gradeId, isDownloadPrintableFlow){
    return (dispatch) => {
        dispatch({
            type: "INITIALIZE_VITALS",
            payload: Promise.resolve({token: token, customerType: customerType, isAndroid: isAndroid, gradeId: gradeId, isDownloadPrintableFlow:isDownloadPrintableFlow})
        }).then((data) => {
            console.log(`I am in the nested initialise Variables: ${data}`);
            dispatch(getWorkbooks(token, gradeId))
        });
    };
}

export function changeGrade(token, gradeId){
    //Note that we are using redux thunk and redux-promise-middleware to chain the dispatches
    return (dispatch) => {
        const response = dispatch({
            type: "CHANGE_GRADE",
            payload: Promise.resolve(gradeId)
        });

        response.then((data) => {
            console.log(`I am in the nested promise: ${data}`)
            dispatch(getWorkbooks(token, gradeId))
        })
    };
}

export function ourAppsClicked(){
    return (dispatch) => {
        dispatch({
            type: "SHOW_OUR_APPS",
            payload: Promise.resolve(true)
        });
    };
}