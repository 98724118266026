import React, {Component} from 'react';
import {Col, Row} from 'react-materialize'
import {Card, CardTitle} from 'react-materialize'

class OurApps extends Component {

    componentWillMount() {
        this.columns = 4;
        this.smallColumns = 6;
        this.androidURLs = {
            mathRescue: "https://play.google.com/store/apps/details?id=com.makkajai.numbersense_fire_water_free&referrer=utm_source%3Dcrosspromotion",
            mathBalance: "https://play.google.com/store/apps/details?id=com.makkajai.numbersense_compensation_free&referrer=utm_source%3Dcrosspromotion",
            mathBridges: "https://play.google.com/store/apps/details?id=com.makkajai.numbersensefree&referrer=utm_source%3Dcrosspromotion",
            mm2: "https://play.google.com/store/apps/details?id=com.makkajai.monstermath2free&referrer=utm_source%3Dcrosspromotion",
            mp: "https://play.google.com/store/apps/details?id=com.makkajai.monster_math_2_mp_free&referrer=utm_source%3Dcrosspromotion",
            multifly: "https://play.google.com/store/apps/details?id=com.makkajai.multiplicationgamefree&referrer=utm_source%3Dcrosspromotion",
            mm1: "https://play.google.com/store/apps/details?id=com.makkajai.monstermathfree&referrer=utm_source%3Dcrosspromotion",
        };
        this.iosURLs = {
            mathRescue: "https://itunes.apple.com/us/app/math-rescue-games-for-rounding/id1287932907?mt=8",
            mathBalance: "https://itunes.apple.com/us/app/math-balance-games-for-kids/id1217326065?mt=8",
            mathBridges: "https://itunes.apple.com/us/app/math-bridges-games-for-kids/id1199323483?mt=8",
            mm2: "https://itunes.apple.com/us/app/monster-math-for-kids-games/id1025450732?mt=8",
            mp: "https://itunes.apple.com/us/app/duel-math-games-1st-4th-grade/id1060040925?mt=8",
            multifly: "https://apps.apple.com/us/app/multifly-multiplication-games/id1502397616?mt=8",
            mm1: "https://itunes.apple.com/us/app/monster-math-kids-fun-games/id931943412?mt=8"

        };
        this.urls = (this.props.isAndroid === true? this.androidURLs : this.iosURLs);
    }

    render() {
        return (<div>
                    {this.renderCards()}
                </div>);
    }

    renderCards = () => {
        return <Row>
            <Col s={this.smallColumns} m={this.columns} l={this.columns}>
                <Card
                    key={"Monster Math 2"}
                    className={"sticky-action"}
                    header={<CardTitle reveal image={"../../crosspromotion/images/mm2.jpg"}
                                       waves='light'> </CardTitle>}
                    title={"Monster Math 2"}
                    reveal={<div>The game is fully customizable to suit to your student’s aptitude and you can choose
                        between
                        basic and advanced skills with a simple toggle. Detailed reports and weekly emails allow you to
                        get an in-depth view of how the student is progressing in math!</div>}
                    actions={[<a key={"Monster Math 2"} className={"btn-large"}
                                 href={this.urls.mm2}>Download
                        Now</a>]}>
                    <p>Monster Math 2 helps k-5 students to practice common core/CBSE/ICSE aligned math skills. This
                        game covers over 70 skills like addition, subtraction, multiplication, division, times tables,
                        factors, multiples, prime numbers and geometry.</p>
                </Card>
            </Col>
            <Col s={this.smallColumns} m={this.columns} l={this.columns}>
                <Card
                    key={"Monster Math 1"}
                    className={"sticky-action"}
                    header={<CardTitle reveal image={"../../crosspromotion/images/mm1.jpg"}
                                       waves='light'> </CardTitle>}
                    title={"Monster Math"}
                    reveal={<div>Be Maxx’s personal math helper as you explore new worlds, battle enemies and find allies in
                        unexpected places by using addition, multiplication, subtraction and division!</div>}
                    actions={[<a key={"Monster Math 1"} className={"btn-large"}
                                 href={this.urls.mm1}>Download
                        Now</a>]}>
                    <p>Have your students practice and learn addition, subtraction, division, times tables and basic
                        problem solving with Monster Math Pro!</p>
                </Card>
            </Col>
        </Row>

    };
}

export default OurApps;
