import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './js/App.js';
import { Provider } from "react-redux"
import store from "./js/Store"
import registerServiceWorker from './js/registerServiceWorker';
import { BrowserRouter, Route } from 'react-router-dom'

ReactDOM.render((
        <Provider store={store}>
            <BrowserRouter>
                <Route path="/" component={App}  />
            </BrowserRouter>
        </Provider>),
    document.getElementById('root')
);
registerServiceWorker();
